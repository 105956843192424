import './App.css';
import image from './supernova.png';

function App() {

  const flavors = ["Slaughter melon",'Banana', 'Apple', 'Honey', 'Mindset', 'Nectar of the Gods', "Bull semen", "Regular semen",
  "Chuck Norris",
  "Green tea",
  "Mean tea",
  "Laxative",
  "Shockolate",
  "Rawberry",
  "Real lightning",
  'Unflavored'];

  const flavorLists = () => {
    const res = [];
    flavors.forEach((flavor) => {
      res.push(<option value={flavor}>{flavor}</option>);
       })
      return <select name="Flavor" id="flavor">{res}</select>;
  }

  return (
    <div class='row'>
      <div class="column_left">
        <img src={image} alt='yeah buddy' 
        width="115%" height="115%" 
        />
      </div>

      <div class="column_right">
            <h1>
            HEGEZEUS
            £69.69 tax included
            </h1>
        <h2>
          Choose your flavor<br></br>
          {flavorLists()}
        </h2>

        <h3>
          Ingredients:
          <li>caffeine (420mg) </li>
          <li>creatine (4g) </li>
          <li>alpha GPC (600mg) </li>
          <li>L-Theanine (400mg) </li>
          <li>sugar (10g) </li>
          <li>taurine (4g) </li>
          <li>L-carnitine (4g) </li>
          <li>L-Tyrosine (2g) </li>
          <li>Lions Mane (4g) </li>
          <li>himolayan pink salt (1g) </li>
          <li>beta alanine 8g </li>
          <li>L-citrulline 8g </li>
          <li>ginseng </li>
        </h3>

        <div>
          <button disabled={true}>SOLD OUT</button>
        </div>

        <h3>
          Customer reviews
          </h3>
          <div>
          * * * * *<br></br>
          I friggin loved it<br></br>
          Aron Hegedus
        </div>

      </div>
      
    </div>

   
  );
}

export default App;
